import React, { useState } from 'react';
import moment from 'moment-timezone';
import { atcb_action } from 'add-to-calendar-button';


const T80Calculator = () => {
  const [departureDate, setDepartureDate] = useState('');
  const [departureTime, setDepartureTime] = useState('');
  const [timezone, setTimezone] = useState('Australia/Sydney');
  const [t80Details, setT80Details] = useState(null);
  const [error, setError] = useState('');

  const calculateT80 = () => {
    setError('');
    if (!departureDate || !departureTime) {
      setError('Please enter both departure date and time.');
      return;
    }

    try {
      // Combine date and time in the selected timezone
      const departureDateTime = moment.tz(`${departureDate} ${departureTime}`, timezone);
      
      // Calculate T-80 (80 hours prior to departure)
      const t80DateTime = departureDateTime.clone().subtract(80, 'hours');

      setT80Details({
        date: t80DateTime.format('YYYY-MM-DD'),
        time: t80DateTime.format('HH:mm'),
        timezone: timezone
      });
    } catch (err) {
      setError('An error occurred while calculating. Please check your inputs.');
      console.error(err);
    }
  };

  const handleAddToCalendar = () => {
    if (!t80Details) return;
  
    const t80DateTime = moment.tz(`${t80Details.date} ${t80Details.time}`, t80Details.timezone);
    const endDateTime = t80DateTime.clone().add(30, 'minutes');
  
    const config = {
      name: "Qantas T-80 Seat Release",
      description: "Time to check for additional Qantas flight seating",
      startDate: t80DateTime.format('YYYY-MM-DD'),
      startTime: t80DateTime.format('HH:mm'),
      endDate: endDateTime.format('YYYY-MM-DD'),
      endTime: endDateTime.format('HH:mm'),
      timeZone: t80Details.timezone,
      options: [
        'Apple',
        'Google',
        'iCal',
        'Outlook.com',
        'Microsoft365',
        'Yahoo'
      ],
      trigger: 'click',
      iCalFileName: "T80-Qantas-Seat-Release",
    };
  
    atcb_action(config);
  };
  
  
    

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100 flex items-center justify-center p-4">
      <div className="bg-white w-full max-w-md rounded-xl shadow-2xl p-6">
        <h1 className="text-center text-2xl font-bold text-blue-800 mb-6">
          T-80 Qantas Seat Release
        </h1>
        
        <form 
          className="space-y-4" 
          onSubmit={(e) => {
            e.preventDefault();
            calculateT80();
          }}
        >
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Departure Date
            </label>
            <input 
              type="date" 
              value={departureDate}
              onChange={(e) => setDepartureDate(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Departure Time
            </label>
            <input 
              type="time" 
              value={departureTime}
              onChange={(e) => setDepartureTime(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Departure Timezone
            </label>
            <select 
              value={timezone} 
              onChange={(e) => setTimezone(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {moment.tz.names().map((tzName) => (
                <option key={tzName} value={tzName}>
                  {tzName}
                </option>
              ))}
            </select>
          </div>

          <button 
            type="submit" 
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
          >
            Calculate T-80 Time
          </button>
        </form>

        {error && (
          <div className="mt-4 p-2 bg-red-100 text-red-700 rounded">
            {error}
          </div>
        )}

        {t80Details && (
          <div className="mt-6 bg-blue-50 p-4 rounded-lg">
            <h2 className="text-xl font-semibold mb-4 text-blue-800">T-80 Details</h2>
            <div className="space-y-2">
              <p className="flex justify-between">
                <span className="font-medium">T-80 Date:</span>
                <span>{t80Details.date}</span>
              </p>
              <p className="flex justify-between">
                <span className="font-medium">T-80 Time:</span>
                <span>{t80Details.time}</span>
              </p>
              <p className="flex justify-between">
                <span className="font-medium">Timezone:</span>
                <span>{t80Details.timezone}</span>
              </p>
            </div>
            
            <button 
            onClick={handleAddToCalendar} 
            className="w-full mt-4 bg-white border border-blue-500 text-blue-600 py-2 px-4 rounded-md hover:bg-blue-50 flex items-center justify-center"
          >
            Add to Calendar
          </button>


          </div>
        )}
      </div>
    </div>
  );
};

export default T80Calculator;
